// src/components/ProductOptions.js
import React from 'react';
import PropTypes from 'prop-types';

const ProductOptions = ({ selectedWeight, quantity, onWeightChange, onQuantityChange, totalPrice, onAddToCart }) => (
  <div className="dropdown">
    <label htmlFor="weight">בחירת משקל: </label>
    <select
      id="weight"
      value={selectedWeight}
      onChange={(e) => onWeightChange(Number(e.target.value))} // Ensure the value is a number
    >
      {[100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 700, 800, 900, 1000].map(weightOption => (
        <option key={weightOption} value={weightOption}>{weightOption}g</option>
      ))}
    </select>
    <label htmlFor="quantity">כמות: </label>
    <input
      type="number"
      id="quantity"
      min="1"
      value={quantity}
      onChange={(e) => onQuantityChange(Number(e.target.value))} // Ensure the value is a number
    />
    <div className="total-price">
      מחיר: {totalPrice.toFixed(2)} ₪
    </div>
    <button className="add-to-cart" onClick={onAddToCart}>
      להוסיף לעגלה
    </button>
  </div>
);

ProductOptions.propTypes = {
  selectedWeight: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  onWeightChange: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  onAddToCart: PropTypes.func.isRequired,
};

export default ProductOptions;