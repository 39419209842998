import React, { useState, useEffect } from 'react';
import styles from '../styles/PhoneLink.module.css';

const PhoneLink = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleClick = (e) => {
    if (!isMobile) {
      e.preventDefault();
      setShowTooltip(true);
    }
  };

  return (
    <div className={styles.phoneContainer}>
      <a 
        href="tel:0586540013" 
        className={styles.phoneNumber}
        onClick={handleClick}
        aria-label="Contact us at 058-6540013"
      >
        058-6540013
      </a>
      {showTooltip && !isMobile && (
        <div className={styles.tooltip}>
          <p>התקשרו אלינו: 0586540013</p>
          <p>שלחו לנו מייל: shedrmeals@gmail.com</p>
          <button onClick={() => setShowTooltip(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default PhoneLink;