// src/components/PayPalIntegration.js

import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export const PayPalButtonWrapper = ({ createOrder, onApprove, onError, isFormSubmitted }) => {
  const [{ isPending }] = usePayPalScriptReducer();

  if (isPending) return <div>Loading PayPal Buttons...</div>;

  return (
    <PayPalButtons
      createOrder={createOrder}
      onApprove={onApprove}
      onError={onError}
      disabled={!isFormSubmitted}
      style={{ layout: "vertical" }}
    />
  );
};

export const createPayPalOrder = (formData, getTotalPrice) => (data, actions) => {
  console.log('Form data received in createPayPalOrder:', formData);
  console.log('Creating PayPal order with pre-filled data');

  const orderData = {
    purchase_units: [
      {
        amount: {
          value: getTotalPrice().toFixed(2),
          currency_code: "ILS"
        }
      }
    ],
    application_context: {
      shipping_preference: "NO_SHIPPING"
    },
    payer: {
      email_address: formData.email,
      phone: {
        phone_number: {
          national_number: formData.phone.replace(/\D/g, '') // Remove non-digit characters
        }
      },
      name: {
        given_name: formData.firstName,
        surname: formData.lastName
      },
      address: {
        address_line_1: `${formData.street} ${formData.houseNumber}`,
        address_line_2: formData.apartmentNumber,
        admin_area_2: formData.city,
        postal_code: formData.zipCode,
        country_code: "IL" // Assuming Israel as the country
      }
    }
  };

  console.log('PayPal order data:', JSON.stringify(orderData, null, 2));

  return actions.order.create(orderData);
};

export const onPayPalApprove = (data, actions) => {
  return actions.order.capture().then(function (details) {
    alert("Transaction completed by " + details.payer.name.given_name);
    // Here you can call your backend API to save the transaction details
  });
};