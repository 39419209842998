// WhatsAppIcon.js
import React, { useState } from 'react';
import '../styles/WhatsAppIcon.css'; // Create this CSS file for styling
import { WHATSAPP_URL } from '../constants/urls';
import whatsapp_icon from '../assets/whatsapp-icon.png';

const message = "היי, יש לי שאלה";

const WhatsAppIcon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="whatsapp-icon">
      <a href={`${WHATSAPP_URL}?text=${encodeURIComponent(message)}`} target="_blank" rel="noopener noreferrer">
        <img src={whatsapp_icon} alt="Chat on WhatsApp" />
      </a>
    </div>
  );
};

export default WhatsAppIcon;