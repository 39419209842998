// src/components/PaymentSuccessPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/PaymentSuccessPage.css';

const PaymentSuccessPage = () => {
  return (
    <div className="payment-success-container">
      <h1>תודה על הזמנתך!</h1>
      <p className="success-message">
        התשלום בוצע בהצלחה והאוכל יגיע אליכם ביום ראשון הקרוב.
        אנחנו ניצור איתכם קשר לפני על מנת לתאם זמן למשלוח.
      </p>
      <Link to="/" className="back-to-home-button">חזרה לדף הבית</Link>
    </div>
  );
};

export default PaymentSuccessPage;