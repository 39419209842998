// src/components/AddToCartMessage.js
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/AddToCartMessage.css';

const AddToCartMessage = ({ message }) => {
  if (!message) return null;

  return (
    <div className="add-to-cart-message">
      {message}
    </div>
  );
};

AddToCartMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AddToCartMessage;