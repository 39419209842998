// src/pages/About.js
import React from 'react';
import '../styles/AboutPage.css'; // Optional: Add a CSS file for styling
import { useState } from 'react';
import { FaMinus, FaPlus, FaArrowLeft } from 'react-icons/fa'; // Import arrow icon if needed
import personalChoice from '../assets/personal_choice.png';
import deliveryDoor from '../assets/delivery_door.png';
import reheatFood from '../assets/reheat_food.png';
import madeInIsrael from '../assets/mdae_in_israel.png';
import highQuality from '../assets/high_quality.png';
import scale from '../assets/scale.png';
import chef from '../assets/chef.png';
import trust from '../assets/trust.png';

const About = () => {
  // FAQ data
  const faqs = [
    { question: 'איך ניתן לבצע הזמנה?', answer: 'בצעו הזמנה דרך האתר שלנו או התקשרו למספר שלנו.' },
    { question: 'עד מתי אפשר להזמין?', answer: 'אפשר להזמין עד יום חמישי בצהריים.' },
    { question: 'מתי מקבלים את האוכל?', answer: 'מקבלים את ההזמנה ביום ראשון.' },
    { question: 'איך מחממים את האוכל?', answer: 'לחממם במיקרוגל בין 3-5 דקות או עד שהכל חם.' },
    { question: 'לאן אנחנו מגיעים?', answer: 'ראשון לציון, בת ים, חולון, נס ציונה, רחובות.' },
    { question: 'כמה עולה המשלוח?', answer: 'משלוח חינם כשמזמינים מעל 250 ₪ או 20 ₪ למשלוח' },
    { question: 'האם אפשר להזמין בפחות מ250 ₪?', answer: 'כן, אבל או להגיע לקחת את המשלוח מראשון לציון או בתופסת תשלום של 20 ₪ למשלוח' },
    // Add more FAQ items as needed
  ];

  // State for expanded FAQ item
  const [expanded, setExpanded] = useState(null);

  // Toggle function for FAQ
  const toggleAnswer = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div id="about-container" className="about-container">
      <h1>אז מי אנחנו?</h1>
      <p>שמירה על תזונה נכונה היא חלק בלתי נפרד מהשגת מטרות בריאותיות ואימוניות, אך זהו לעיתים אתגר גדול במיוחד. התמודדות עם תכנון מנות, הכנה של מנות ומציאת זמן לבשל יכולים להיות מאוד מאתגרים.</p>
      <p>במהלך השנים האחרונות, חיפשנו פתרונות לארוחות מזינות ואיכותיות. הגענו להבנה שהפתרונות הזמינים בשוק אינם עונים על הציפיות שלנו. שקלנו אפשרויות כמו מסעדות ואפליקציות משלוחים, אך מצאנו שהן יקרות מאוד, לא מתאימות לערכים התזונתיים שלנו ולרוב אינן עקביות בגודל המנות.</p>
      <p>התחלנו להתנסות ולהכין מנות בריאות ועשירות בחלבון. גילינו שהכנת מנות לכל השבוע ביום אחד זהו פתרון מושלם עבור מי שמעוניין לשמור על תזונה בריאה מבלי להיתקל בקשיים כמו תכנון תפריטים מורכב, חוסר זמן להכנת אוכל, או קושי בהשגת אוכל בריא מבחינת ערכים תזונתיים וקלוריות.</p>
      <p>מכאן, נוצר הרעיון להביא את החוויות האישיות שלנו לשוק ולהציע לכם שירות המבטיח אוכל בריא, מדוד ומוכן מראש, שיהיה זמין לכם בכל עת.</p>

      {/* Diagram */}
      <div className="how-it-works">
        <h2 className="how-it-works-header">איך זה עובד?</h2>
        <div className="diagram">
          <div className="diagram-item">
            <img src={personalChoice} alt="בחירה אישית" />
            <div className="diagram-text">
              <h3>בחירה אישית מהתפריט</h3>
              <p>בחרו את המנות שמדברות אליכם ואנחנו נדאג לכל השאר</p>
            </div>
          </div>

          <FaArrowLeft className="arrow-icon" /> {/* Arrow between the first and second item */}

          <div className="diagram-item">
            <img src={deliveryDoor} alt="מגיעים עד אליכם" />
            <div className="diagram-text">
              <h3>מגיעים עד לדלת שלכם</h3>
              <p>הזמינו בקלות, והמשלוח יגיע אליכם במהירות ובנוחות</p>
            </div>
          </div>

          <FaArrowLeft className="arrow-icon" /> {/* Arrow between the second and third item */}

          <div className="diagram-item">
            <img src={reheatFood} alt="חימום במיקרו והארוחה מוכנה!" />
            <div className="diagram-text">
              <h3>חימום במיקרו והארוחה מוכנה!</h3>
              <p>חימום קצר במיקרוגל והארוחה מוכנה בדיוק כפי שחלמתם</p>
            </div>
          </div>
        </div>
      </div>
      
        {/* Our Values Section */}
      <div className="our-values">
        <h2 className="our-values-header">הערכים שלנו</h2>
        <div className="values-container">
          <div className="value-item">
            <img src={madeInIsrael} alt="כחול לבן" />
            <p>כחול לבן: אנחנו תומכים בתעשייה המקומית ומקפידים להשתמש במוצרים המיוצרים בישראל בלבד.</p>
          </div>
          <div className="value-item">
            <img src={highQuality} alt="value 1" />
            <p>איכות גבוהה: אנו מקפידים על איכות החומרים והמנות שלנו.</p>
          </div>
          <div className="value-item">
            <img src={chef} alt="value 2" />
            <p>מתכונים מקצועיים: כל מנה מוכנה עם מתכונים שנבחרו בקפידה על ידי שפים מנוסים, כדי להבטיח שילוב מושלם של טעמים ותזונה.</p>
          </div>
          <div className="value-item">
            <img src={scale} alt="value 3" />
            <p>אנחנו דואגים להתאים את המנות לצרכים התזונתיים שלכם, כך שתוכלו ליהנות מאכילה בריאה ומדויקת</p>
          </div>
          <div className="value-item">
            <img src={trust} alt="value 3" />
            <p>שקיפות ואמון: אנו מספקים מידע מלא על הערכים התזונתיים של כל מנה, כדי שתדעו בדיוק מה אתם אוכלים.</p>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div id="faq-container" className="faq-container">
        <h2 className="faq-header">שאלות נפוצות</h2>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3 className="faq-question" onClick={() => toggleAnswer(index)}>
              {expanded === index ? <FaMinus /> : <FaPlus />} {faq.question}
            </h3>
            {expanded === index && <p className="faq-answer">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
