// src/components/Facts.js
import React from 'react';
import '../styles/Facts.css'; // Import the CSS file for styling

// Import icons
import deliveryIcon from '../assets/delivery.png';
import foodContainers from '../assets/food_containers.webp';
import freshIngredients from '../assets/fresh_ingredients.jpg';
import orderReceived from '../assets/order_received.png';
import locations from '../assets/locations.png';
// Import other icons similarly

const facts = [
  {
    icon: foodContainers,
    text: "מינימום להזמנה 250 ₪."
  },
  {
    icon: freshIngredients,
    text: "אנחנו משתמשים רק במרכיבים טריים ומקומיים."
  },
  {
    icon: orderReceived,
    text: "הזמנות מתקבלות עד יום חמישי ב12 בצהריים."
  },
  {
    icon: deliveryIcon,
    text: "הזמנות נשלחות ביום ראשון בלבד."
  },
  {
    icon: locations,
    text: "אזורי חלוקה בפירוט בשאלות ותשובות"
  },

  // Add other facts here
];

const splitTextIntoLines = (text, charsPerLine) => {
  const words = text.split(' ');
  let lines = [];
  let currentLine = '';

  words.forEach(word => {
    if ((currentLine + word).length > charsPerLine) {
      lines.push(currentLine.trim());
      currentLine = '';
    }
    currentLine += word + ' ';
  });

  if (currentLine) {
    lines.push(currentLine.trim());
  }

  return lines;
};

const Facts = () => {
  return (
    <section className="facts">
      <h2 className="section-header">צריך לדעת ש...</h2>
      <div className="facts-container">
        {facts.map((fact, index) => {
          const lines = splitTextIntoLines(fact.text, 15); // Adjust 20 to your preferred character count per line
          return (
            <div className="fact-item" key={index}>
              <img src={fact.icon} alt={`Fact ${index + 1}`} className="fact-icon" />
              <div className="fact-text">
                {lines.map((line, lineIndex) => (
                  <p key={lineIndex} className="fact-line">{line}</p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Facts;
