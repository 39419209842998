// src/components/HotItems.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HotItems.css';

import { proteins, carbs, veggies } from '../data/products';

const categories = [
  { name: 'חלבונים', items: proteins, hash: 'proteins' },
  { name: 'פחמימות', items: carbs, hash: 'carbs' },
  { name: 'ירקות', items: veggies, hash: 'veggies' }
];

const HotItems = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const rotateItems = () => {
      const newItems = categories.map(category => ({
        ...category.items[Math.floor(Math.random() * category.items.length)],
        category: category.name,
        hash: category.hash
      }));
      setCurrentItems(newItems);
    };

    rotateItems();
    const intervalId = setInterval(rotateItems, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleItemClick = (itemId, categoryHash) => {
    navigate(`/store#${categoryHash}`);
  };

  return (
    <section className="hot-items">
      <h2 className="section-header">הפריטים החמים שלנו</h2>
      <div className="items-container">
        {currentItems.map((item) => (
          <div key={item.id} className="item">
            <h3 className="item-category">{item.category}</h3>
            <button className="item-button" onClick={() => handleItemClick(item.id, item.hash)}>
              <img src={item.imageUrl} alt={item.name} className="item-image" />
              <p className="item-name">{item.name}</p>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HotItems;