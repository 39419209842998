// src/data/products.js
import chickenBreasts from '../assets/chicken_breasts.png';
import salmon from '../assets/salmon.png';
import meatballs from '../assets/meatballs.png';
import chickenThighs from '../assets/chicken_thighs.png';
import rice from '../assets/rice.png';
import pasta from '../assets/pasta.png';
import pastaAlfredo from '../assets/pasta_alfredo.png';
import sweetPotatoes from '../assets/sweet_potato.png';
import potato from '../assets/potato.png';
import culiflower from '../assets/culiflower.png';
import antiPasti from '../assets/anti_pasty.png';
// Import other images as needed

export const proteins = [
  { 
    id: '1', 
    name: 'חזה עוף', 
    pricePer100g: 8.5, 
    imageUrl: chickenBreasts,
    nutritionValues: {
      calories: 165,
      protein: 31,
      carbs: 3.5,
      fats: 4.5,
      fiber: 0
    }
  },
  { 
    id: '2', 
    name: 'סלמון', 
    pricePer100g: 23, 
    imageUrl: salmon,
    nutritionValues: {
      calories: 180,
      protein: 25,
      carbs: 1,
      fats: 10,
      fiber: 0
    }
  },
  { 
    id: '3', 
    name: 'קציצות בקר', 
    pricePer100g: 13, 
    imageUrl: meatballs,
    nutritionValues: {
      calories: 230,
      protein: 19,
      carbs: 10,
      fats: 14,
      fiber: 1
    }
  },
  { 
    id: '4', 
    name: 'פרגיות', 
    pricePer100g: 11.5, 
    imageUrl: chickenThighs,
    nutritionValues: {
      calories: 220,
      protein: 24,
      carbs: 3,
      fats: 12,
      fiber: 0
    }
  }
  // Add more protein items
];

export const carbs = [
  { 
    id: '11', 
    name: 'אורז', 
    pricePer100g: 5, 
    imageUrl: rice,
    nutritionValues: {
      calories: 140,
      protein: 2.5,
      carbs: 27,
      fats: 4,
      fiber: 1
    }
  },
  { 
    id: '12', 
    name: 'פסטה', 
    pricePer100g: 5, 
    imageUrl: pasta,
    nutritionValues: {
      calories: 154,
      protein: 5.6,
      carbs: 32,
      fats: 1,
      fiber: 0
    }
  },
  { 
    id: '13',   
    name: 'פסטה ברוטב אלפרדו', 
    pricePer100g: 7, 
    imageUrl: pastaAlfredo,
    nutritionValues: {
      calories: 130,
      protein: 7.2,
      carbs: 17,
      fats: 3,
      fiber: 0
    }
  },
  { 
    id: '14', 
    name: 'תפוח אדמה בתנור', 
    pricePer100g: 5, 
    imageUrl: potato,
    nutritionValues: {
      calories: 100,
      protein: 2.5,
      carbs: 23,
      fats: 3.5,
      fiber: 2.5
    }
  },
  { 
    id: '15', 
    name: 'בטטה בתנור', 
    pricePer100g: 6, 
    imageUrl: sweetPotatoes,
    nutritionValues: {
      calories: 100,
      protein: 1.5,
      carbs: 23,
      fats: 3.5,
      fiber: 3.5
    }
  }
  // Add more carb items
];

export const veggies = [
  { 
    id: '21', 
    name: 'אנטי פאסטי', 
    pricePer100g: 6, 
    imageUrl: antiPasti,
    nutritionValues: {
      calories: 34,
      protein: 2.8,
      carbs: 7,
      fats: 0.4,
      fiber: 2.6
    }
  },
  { 
    id: '22', 
    name: 'כרובית בתנור', 
    pricePer100g: 6, 
    imageUrl: culiflower,
    nutritionValues: {
      calories: 41,
      protein: 0.9,
      carbs: 10,
      fats: 0.2,
      fiber: 2.8
    }
  },
  // Add more veggie items
];