import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HotItems from './components/HotItems'; // Ensure you have this component
import Reviews from './components/Reviews'; // Import the Reviews component
import Facts from './components/Facts'; // Import the Facts component
import Footer from './components/Footer'; // Import the Footer component
import AboutPage from './pages/AboutPage'; // Import the AboutPage component
import StorePage from './pages/StorePage'; // Import the StorePage component
import CartPage from './components/CartPage'; // Import the CartPage component
import CheckoutPage from './components/CheckoutPage';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import { CartProvider } from './contexts/CartContext'; // Import CartProvider
import WhatsAppIcon from './components/WhatAppIcon';

function App() {
  return (
    <CartProvider> {/* Wrap everything with CartProvider */}
      <Router> {/* Wrap everything with BrowserRouter */}
        <Header />
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/store" element={<StorePage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          {/* Add other routes here */}
          <Route path="/" element={
            <>
              <HotItems />
              <Reviews /> {/* Add the Reviews section here */}
              <Facts /> {/* Add the Facts section */}
            </>
          } />
        </Routes>
        <WhatsAppIcon />
        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;
