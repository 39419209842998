// src/components/FloatingCartButton.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import styles from '../styles/FloatingCartButton.module.css';
import cartIcon from '../assets/cart-icon.png';

const FloatingCartButton = () => {
  const { cartItems } = useCart();

  return (
    <Link to="/cart" className={styles.floatingCartButton}>
      <img src={cartIcon} alt="Cart" className={styles.cartIcon} />
      <span className={styles.cartCount}>{cartItems.length}</span>
    </Link>
  );
};

export default FloatingCartButton;

