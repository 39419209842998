// src/hooks/useProductData.js
import { useState, useCallback } from 'react';
import { useCart } from '../contexts/CartContext';

const useProductData = (id, name, pricePer100g, imageUrl, onAddToCart) => {
  const [selectedWeight, setSelectedWeight] = useState(100); // Default to 100g
  const [totalPrice, setTotalPrice] = useState(pricePer100g);
  const [quantity, setQuantity] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);

  const { addToCart } = useCart();

  const handleWeightChange = useCallback((newWeight) => {
    setSelectedWeight(newWeight);
    setTotalPrice((newWeight / 100) * pricePer100g * quantity);
  }, [pricePer100g, quantity]);

  const handleQuantityChange = useCallback((newQuantity) => {
    setQuantity(newQuantity);
    setTotalPrice((selectedWeight / 100) * pricePer100g * newQuantity);
  }, [selectedWeight, pricePer100g]);

  const handleShowOptions = useCallback(() => {
    setShowDropdown(prev => !prev);
  }, []);

  const handleAddToCart = useCallback(() => {
    const item = {
      id,
      name,
      pricePer100g,
      weight: selectedWeight,
      quantity,
      totalPrice,
      imageUrl
    };
    addToCart(item);
    // Create the Hebrew message
    const hebrewMessage = `${quantity} ${name} (${selectedWeight} גרם) נוסף לעגלה`;
    onAddToCart(hebrewMessage);
  }, [id, name, pricePer100g, selectedWeight, quantity, totalPrice, imageUrl, addToCart, onAddToCart]);
  return {
    totalPrice,
    quantity,
    showDropdown,
    selectedWeight,
    handleWeightChange,
    handleQuantityChange,
    handleShowOptions,
    handleAddToCart
  };
};

export default useProductData;