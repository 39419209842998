// src/pages/StorePage.js
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/StorePage.css';
import Product from '../components/Product';
import { proteins, carbs, veggies } from '../data/products';
import FloatingCartButton from '../components/FloatingCartButton';
import AddToCartMessage from '../components/AddToCartMessage';

const ProductCategory = ({ title, products, id, onAddToCart }) => {
  const ref = useRef(null);

  return (
    <div ref={ref} id={id} className="product-category">
      <h2>{title}</h2>
      <div className="product-grid">
        {products.map(product => (
          <Product
            key={product.id}
            id={product.id}
            name={product.name}
            pricePer100g={product.pricePer100g}
            imageUrl={product.imageUrl}
            nutritionValues={product.nutritionValues}
            onAddToCart={onAddToCart} // Pass the function to handle add to cart
          />
        ))}
      </div>
    </div>
  );
};

const StorePage = () => {
  const location = useLocation();
  const [addToCartMessage, setAddToCartMessage] = useState('');

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleAddToCart = (message) => {
    setAddToCartMessage(message);
    setTimeout(() => setAddToCartMessage(''), 3000); // Clear message after 3 seconds
  };

  return (
    <div className="store-container">
      <ProductCategory title="חלבונים" products={proteins} id="proteins" onAddToCart={handleAddToCart} />
      <ProductCategory title="פחמימות" products={carbs} id="carbs" onAddToCart={handleAddToCart} />
      <ProductCategory title="ירקות" products={veggies} id="veggies" onAddToCart={handleAddToCart} />
      <FloatingCartButton />
      <AddToCartMessage message={addToCartMessage} />
    </div>
  );
};

export default StorePage;