// utils/navigation.js
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const smoothScrollTo = (targetId) => {
  const element = document.getElementById(targetId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const useNavigation = () => {
  const navigate = useNavigate();

  return useCallback((path, targetId = null) => {
    navigate(path);
    setTimeout(() => {
      if (targetId) {
        smoothScrollTo(targetId);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, 0);
  }, [navigate]);
};