// src/components/Product.js
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Product.css';
import ProductOptions from './ProductOptions';
import useProductData from '../hooks/useProductData';

const Product = ({ id, name, pricePer100g, imageUrl, nutritionValues, onAddToCart }) => {
  const {
    totalPrice,
    quantity,
    showDropdown,
    selectedWeight,
    handleWeightChange,
    handleQuantityChange,
    handleShowOptions,
    handleAddToCart
  } = useProductData(id, name, pricePer100g, imageUrl, onAddToCart);

  const adjustedNutritionValues = Object.keys(nutritionValues).reduce((acc, key) => {
    acc[key] = (nutritionValues[key] * selectedWeight) / 100;
    return acc;
  }, {});

  return (
    <div className="product">
      <img src={imageUrl} alt={name} className="product-image" />
      <h3>{name}</h3>
      <div className="nutrition-values">
        <p>ערכים תזונתיים (ל-{selectedWeight} גרם):</p>
        <ul>
          <li>קלוריות: {adjustedNutritionValues.calories.toFixed(1)}</li>
          <li>חלבון: {adjustedNutritionValues.protein.toFixed(1)}</li>
          <li>פחמימות: {adjustedNutritionValues.carbs.toFixed(1)}</li>
          <li>שומנים: {adjustedNutritionValues.fats.toFixed(1)}</li>
          <li>סיבים: {adjustedNutritionValues.fiber.toFixed(1)}</li>
        </ul>
      </div>
      <div className="product-price">
        מחיר ל 100 גרם: {pricePer100g} ₪
      </div>
      <button className="toggle-options" onClick={handleShowOptions}>
        {showDropdown ? 'הסתר אפשרויות' : 'הצג אפשרויות'}
      </button>
      {showDropdown && (
        <ProductOptions
          selectedWeight={selectedWeight}
          quantity={quantity}
          onWeightChange={handleWeightChange}
          onQuantityChange={handleQuantityChange}
          totalPrice={totalPrice}
          onAddToCart={handleAddToCart}
        />
      )}
    </div>
  );
};

Product.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pricePer100g: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  nutritionValues: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fats: PropTypes.number.isRequired,
    fiber: PropTypes.number.isRequired,
  }).isRequired,
  onAddToCart: PropTypes.func.isRequired,
};

export default Product;