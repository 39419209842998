// src/components/Reviews.js
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import '../styles/Reviews.css';

// Import Swiper modules
import { Navigation, Autoplay } from 'swiper/modules';

// Import images
import img1 from '../assets/1.jpg';
import img2 from '../assets/2.jpg';
import img3 from '../assets/3.jpg';
import img4 from '../assets/4.jpg';
import img5 from '../assets/5.jpg';
import img6 from '../assets/6.jpg';

const Reviews = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <section className="reviews">
      <h2 className="section-header">ביקורות</h2>
      <div className="reviews-slider">
        <Swiper
          loop={true}
          slidesPerView={5}
          spaceBetween={10}
          grabCursor={true}
          autoplay={{ delay: 10000 }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          modules={[Navigation, Autoplay]}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          breakpoints={{
            1454: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
            },
            640: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            }
          }}
        >
          <SwiperSlide>
            <img src={img1} alt="Review 1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img2} alt="Review 2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img3} alt="Review 3" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img4} alt="Review 4" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img5} alt="Review 5" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img6} alt="Review 6" />
          </SwiperSlide>
        </Swiper>
        {/* Navigation buttons */}
        <div ref={nextRef} className="swiper-button-prev"></div>
        <div ref={prevRef} className="swiper-button-next"></div>
      </div>
    </section>
  );
};

export default Reviews;