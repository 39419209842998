import React, { useState, useEffect } from 'react';
import { useCart } from '../contexts/CartContext';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { validateField, isFormValid, fieldLabels, requiredFields } from '../utils/formValidation';
import { PayPalButtonWrapper, createPayPalOrder } from './PayPalIntegration';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase.js';
import { useNavigation } from '../utils/navigation';
import { getInitialFormData, updateFormField, clearFormData } from '../utils/formStorage';
import '../styles/CheckoutPage.css';

const CheckoutPage = () => {
  const { cartItems, getTotalPrice, clearCart } = useCart();
  const [formData, setFormData] = useState(getInitialFormData);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [userDocId, setUserDocId] = useState(null);

  const navigate = useNavigation();
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = updateFormField(formData, name, value);
    setFormData(updatedFormData);
    setErrors(prevState => ({ ...prevState, [name]: validateField(name, value) }));
  };

  useEffect(() => {
    setIsValid(isFormValid(formData, errors));
  }, [formData, errors]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid) {
      try {
        const userInfo = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          timestamp: new Date()
        };
        const docRef = await addDoc(collection(db, 'users'), userInfo);
        setUserDocId(docRef.id);
        setIsFormSubmitted(true);
        // Don't clear form data here, as we still need it for the order
      } catch (error) {
        console.error('Error adding user info: ', error);
      }
    } else {
      console.log('Form is not valid');
    }
  };

  const onPayPalApprove = async (data, actions) => {
    return actions.order.capture().then(async function (details) {
      try {
        const orderData = {
          userId: userDocId,
          ...formData,
          items: cartItems.map(item => ({
            id: item.id,
            name: item.name,
            weight: item.weight,
            quantity: item.quantity,
            price: (item.weight / 100 * item.pricePer100g * item.quantity).toFixed(2)
          })),
          totalPrice: getTotalPrice().toFixed(2),
          paymentDetails: details,
          timestamp: new Date()
        };

        const docRef = await addDoc(collection(db, 'orders'), orderData);
        console.log('Order submitted with ID: ', docRef.id);
        clearCart();
        clearFormData(); // Clear stored form data after successful order
        navigate('/payment-success');
      } catch (error) {
        console.error('Error submitting order: ', error);
        // Handle error (show error message to user)
      }
    });
  };

  return (
    <PayPalScriptProvider options={{ 
      "client-id": "ARWAl7Z-H_lovbcxvR7TTVy8GSliCAJakv5bw3xypyNLLl7itw3iHs2BxHsfskq2F1wMB_SwW-H9DXEy", 
      currency: "ILS",
      locale: "he_IL", // Hebrew (Israel)
      // or "en_IL" for English (Israel)
      components: "buttons",
      debug: true
    }}>
      <div className="checkout-page">
        <div className="checkout-form">
          <h2>פרטי משלוח</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-fields">
              {Object.entries(formData).map(([key, value]) => (
                <div className="form-group" key={key}>
                  <label htmlFor={key}>{fieldLabels[key]}:</label>
                  <input
                    type={key === 'email' ? 'email' : 'text'}
                    id={key}
                    name={key}
                    value={value}
                    onChange={handleInputChange}
                    required={requiredFields.includes(key)}
                  />
                  {errors[key] && <span className="error-message">{errors[key]}</span>}
                </div>
              ))}
            </div>
            <button type="submit" className="continue-button" disabled={!isValid || isFormSubmitted}>
              {isFormSubmitted ? 'פרטים נשמרו' : 'המשך'}
            </button>
            <button type="button" className="clear-button" onClick={() => {
              clearFormData();
              setFormData(getInitialFormData());
              setIsFormSubmitted(false);
            }}>
              נקה טופס
            </button>
          </form>
        </div>
        <div className="order-summary">
          <h2>סיכום הזמנה</h2>
          <div className="order-items-container">
            {cartItems.map((item) => (
              <div key={`${item.id}-${item.weight}`} className="order-item">
                <span className="item-name">{item.name} ({item.weight}g)</span>
                <div className="item-details">
                  <span className="item-quantity">כמות: {item.quantity}</span>
                  <span className="item-price">₪{(item.weight / 100 * item.pricePer100g * item.quantity).toFixed(2)}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="order-total">
            <strong>סה"כ לתשלום: ₪{getTotalPrice().toFixed(2)}</strong>
          </div>
          <div className="paypal-button-container">
            {isFormSubmitted ? (
              <PayPalButtonWrapper
                createOrder={(data, actions) => {
                  console.log('Creating order with form data:', formData);
                  return createPayPalOrder(formData, getTotalPrice)(data, actions);
                }}
                onApprove={(data, actions) => {
                  console.log('Payment approved:', data);
                  return actions.order.capture().then(function(details) {
                    console.log('Capture result:', details);
                    onPayPalApprove(data, actions);
                  });
                }}
                onError={(err) => {
                  console.error('PayPal error:', err);
                }}
                isFormSubmitted={isFormSubmitted}
              />
            ) : (
              <p className="form-message">אנא מלא את כל פרטי הטופס לפני התשלום</p>
            )}
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default CheckoutPage;