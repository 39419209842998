// src/utils/formValidation.js

export const fieldLabels = {
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    email: 'אימייל',
    phone: 'טלפון',
    zipCode: 'מיקוד',
    city: 'עיר',
    street: 'רחוב',
    houseNumber: 'מספר בית',
    apartmentNumber: 'מספר דירה',
    gateLetter: 'אות כניסה(לא חובה)'
  };
  
  export const requiredFields = ['firstName', 'lastName', 'email', 'phone', 'zipCode', 'city', 'street', 'houseNumber', 'apartmentNumber'];
  
  export const validateField = (name, value) => {
    switch(name) {
      case 'email':
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'כתובת אימייל לא תקינה';
      case 'phone':
        return /^[0-9]{10}$/.test(value) ? '' : 'מספר טלפון לא תקין';
      case 'zipCode':
        return /^[0-9]{7}$/.test(value) ? '' : 'מיקוד לא תקין';
      case 'houseNumber':
        return /^\d+$/.test(value) ? '' : `חובה למלא שדה ${fieldLabels[name]}`;
      case 'city':
        return /^(?=.*[a-zA-Zא-ת].*[a-zA-Zא-ת])[a-zA-Zא-ת\s]+$/.test(value) ? '' : `חובה למלא שדה ${fieldLabels[name]}`;
      case 'street':
        return /^(?=.*[a-zA-Zא-ת].*[a-zA-Zא-ת])[a-zA-Zא-ת\s]+$/.test(value) ? '' : `חובה למלא שדה ${fieldLabels[name]}`;
      case 'firstName':
        return /^(?=.*[a-zA-Zא-ת].*[a-zA-Zא-ת])[a-zA-Zא-ת\s]+$/.test(value) ? '' : `חובה למלא שדה ${fieldLabels[name]}`;
      case 'lastName':
        return /^(?=.*[a-zA-Zא-ת].*[a-zA-Zא-ת])[a-zA-Zא-ת\s]+$/.test(value) ? '' : `חובה למלא שדה ${fieldLabels[name]}`;
      case 'apartmentNumber':
        return /^\d+$/.test(value) ? '' : `חובה למלא שדה ${fieldLabels[name]}`;
      case 'gateLetter':
        return ''; // These are optional
      default:
        return value.trim() === '' ? 'שדה זה הוא חובה' : '';
    }
  };
  
  export const isFormValid = (formData, errors) => {
    return requiredFields.every(field => formData[field].trim() !== '' && !errors[field]);
  };