// src/utils/formStorage.js

const FORM_DATA_KEY = 'checkoutFormData';

// Initial form structure
const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    zipCode: '',
    city: '',
    street: '',
    houseNumber: '',
    apartmentNumber: '',
    gateLetter: ''
};

// Save form data to localStorage
export const saveFormData = (data) => {
    localStorage.setItem(FORM_DATA_KEY, JSON.stringify(data));
};

// Load form data from localStorage
export const loadFormData = () => {
    const savedData = localStorage.getItem(FORM_DATA_KEY);
    return savedData ? JSON.parse(savedData) : null;
};

// Clear form data from localStorage
export const clearFormData = () => {
    localStorage.removeItem(FORM_DATA_KEY);
};

// Get initial form data (either saved data or empty form)
export const getInitialFormData = () => {
    const savedData = loadFormData();
    return savedData || initialFormData;
};

// Update a single field in the form data
export const updateFormField = (currentData, fieldName, fieldValue) => {
    const updatedData = { ...currentData, [fieldName]: fieldValue };
    saveFormData(updatedData);
    return updatedData;
};