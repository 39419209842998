// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

console.log('Current environment:', process.env.NODE_ENV);

const firebaseConfig = {
    apiKey: "AIzaSyCm6iD9fQ1qljYZAdDU9dGEDn9JJ-Ax97Y",
    authDomain: "meal-prep-shreder.firebaseapp.com",
    projectId: "meal-prep-shreder",
    storageBucket: "meal-prep-shreder.appspot.com",
    messagingSenderId: "388600001947",
    appId: "1:388600001947:web:ada0856f8c745f23eadd3e"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

if (process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(db, 'localhost', 8080);
    console.log('Connected to Firestore emulator');
}

export { db };